import React from 'react';
import NasaApod from './componentes/NasApod';


const Plantilla = () => {
    return <> 

    
     <NasaApod></NasaApod>
     <index></index>
    
    
    </>

}
    export default Plantilla;
