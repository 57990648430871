import React, { useEffect, useState } from 'react';
import axios from 'axios';
import "../css/galeria.css";

const NasaApod = () => {
  const [apodData, setApodData] = useState(null);
  const [galleryData, setGalleryData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchApod = async () => {
      try {
        const apodResponse = await axios.get('https://api.nasa.gov/planetary/apod', {
          params: {
            api_key: 'sSv6iSYAc7KRUtaL4ZWarLjdeRi6Vc1cLYin6tow',
          },
        });
        setApodData(apodResponse.data);

        const galleryResponse = await axios.get('https://images-api.nasa.gov/search', {
          params: {
            q: 'galaxy', // Puedes cambiar el término de búsqueda
            media_type: 'image',
          },
        });
        setGalleryData(galleryResponse.data.collection.items);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchApod();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const elements = document.querySelectorAll('.box, .apod-container');
      elements.forEach((el) => {
        const rect = el.getBoundingClientRect();
        if (rect.top < window.innerHeight) {
          el.classList.add('scroll-animation');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div>
      <header>
        <h1>UniverseHome</h1>
      </header>
      {apodData && (
        <div className="apod-container">
          <h1>{apodData.title}</h1>
          <p>{apodData.date}</p>
          <p>{apodData.explanation}</p>
          <img src={apodData.url} alt={apodData.title} />
        </div>
      )}
      <h2>Galería de imágenes</h2>
      <div className="gallery">
        {galleryData.map((item) => (
          <div key={item.data[0].nasa_id} className="box">
            <img src={item.links[0].href} alt={item.data[0].title} />
            <p>{item.data[0].title}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NasaApod;
